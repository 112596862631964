<template>
    <div>
        <WelcomeSection />
        <TheHow :projects="projectsArray" />
        <TheWhatSection />
        <TheTextWithLinkSection />
        <TheWhySection />
    </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#app'
import WelcomeSection from '~/components/sections/WelcomeSection.vue'
import TheHow from '~/components/sections/TheHowSection.vue'
import TheWhatSection from '~/components/sections/TheWhatSection.vue'
import TheTextWithLinkSection from '~/components/sections/TheTextWithLinkSection.vue'
import TheWhySection from '~/components/sections/TheWhySection.vue'
import { onMounted, useHead, useRoute } from '#imports'
import { getMetaLinks, getMetaTags } from '~/helpers/metaTags'
import json from '@/assets/data/projects-extended-short.json'

const route = useRoute()
const nameSite = useRuntimeConfig().public.SITE_URL
const projectsArray = ref([])

useHead({
    title: 'Spellcaster | Home',
    meta: getMetaTags({
        title: 'Spellcaster | Home',
        description: 'Spellcaster is an independent and international pop-up creative shop. We are a collective of curious and driven problem-solvers.',
        image: 'https://spellcaster.agency/storage/cache/ji/jimmy-fantastic-179fe927ef1a659422299828605b2d34.jpeg',
        url: route.path?.substring(1)
    }),
    link: getMetaLinks({
        rel: 'alternate',
        mainRel: 'canonical',
        mainHref: nameSite
    })
})

onMounted(async () => {
    projectsArray.value = json as []
    const id = route.query?.id

    if (id && typeof id === 'string') {
        setTimeout(() => {
            const el = document.getElementById(id)
            el && window.scrollTo(0, el.offsetTop)
        }, 500)
    }
})
</script>
