<template>
    <section class="container the-what-section section">
        <div>
            <div class="section-number-heading the-what-section__section-number-heading">
                <span>02</span>
                <h2>The what</h2>
            </div>

            <div class="section-head-description">
                If you’re wondering what Spellcaster can do for you… well, here’s a whole list of things.
            </div>
        </div>

        <div class="the-what-section__background" />

        <div class="the-what-section__rows">
            <div v-for="(row, i) in rows" :key="i" class="the-what-section__row" :class="`the-what-section__row-${i}`">
                <RunningLine
                    :tags="row"
                    :rtl="!!(i % 2)"
                    :speed="getSpeed(i)"
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from '#imports'
import RunningLine from '~/components/universal/RunningLine.vue'
import { headingStartAnimation, fromOpacityAnimation } from '~/utils/gsap'
import json from '@/assets/data/init.json'

const rows = ref([])

const getSpeed = (i: number) => {
    switch (i) {
    case 0:
        return 170000
    case 2:
        return 100000
    case 4:
        return 60000
    default:
        return 30000
    }
}

onMounted(async () => {
    const whatwedo = json.locales.whatwedo
    if (whatwedo) {
        Object.keys(whatwedo).forEach((key) => {
            rows.value.push([whatwedo[key].title, whatwedo[key].title, whatwedo[key].title])
            rows.value.push(whatwedo[key].tags.split(','))
        })
    }
    nextTick(() => setTimeout(() => {
        headingStartAnimation('.the-what-section__section-number-heading')
        fromOpacityAnimation('.the-what-section__row', rows.value.length - 1)
    }, 500))
})
</script>
