<template>
    <section class="the-text-with-link-section container section">
        <div class="the-text-with-link-section__background" />

        <div class="the-text-with-link-section__wrapper">
            <div class="the-text-with-link-section__text">
                <a class="welcome__text__cta" href="/about" v-bind="buttonData" :data-cta="buttonData.title">
                    The work we create drives <span>attention</span>, impacts <span>culture</span> and builds tangible <span>business value</span>.
                </a>
            </div>

            <nuxt-link :to="`about/`" class="the-how-section__project-link-welcome">
                <span>About us</span>
                <img :src="'/img/icons/arrow-right.svg'" alt="Link arrow">
            </nuxt-link>
        </div>
    </section>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from '#imports'
import { CIRCLE_BUTTON_ARROWS } from '~/types/buttons'
import { contentStartAnimation } from '~/utils/gsap'

const buttonData = ref({
    title: 'about us',
    link: '/about',
    arrow: CIRCLE_BUTTON_ARROWS.RIGHT
})

onMounted(() => nextTick(() => setTimeout(() => {
    contentStartAnimation(['.the-text-with-link-section__wrapper'])
}, 500)))
</script>
