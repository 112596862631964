<template>
    <div v-if="showSwiper" class="running-line">
        <Swiper
            :autoplay="{
                delay: 1,
                disableOnInteraction: false
            }"
            :centered-slides="true"
            :allow-touch-move="false"
            slides-per-view="auto"
            :space-between="4"
            :loop="true"
            :free-mode="true"
            :modules="modules"
            :speed="speed"
            :dir="rtl && 'rtl'"
        >
            <SwiperSlide v-if="tags.length && tagsProjects.length">
                <span v-for="(item, i) in tags" :key="i">
                    <NuxtLink :to="tagsProjects[i]" data-cta="view project">
                        {{ item }}
                    </NuxtLink>
                </span>
            </SwiperSlide>
            <SwiperSlide v-if="tags.length && tagsProjects.length">
                <span v-for="(item, i) in tags" :key="i + '-copy'">
                    <NuxtLink :to="tagsProjects[i]" data-cta="view project">
                        {{ item }}
                    </NuxtLink>
                </span>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script lang="ts" setup>
import { Pagination, FreeMode, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'
import 'swiper/css/autoplay'
import { onMounted, ref } from '#imports'
import json from '@/assets/data/projects-extended.json'

const modules = [Pagination, FreeMode, Autoplay]

interface Project {
  id: number | string
  title: string
  slug: string
}

interface Props {
  tags: string[]
  rtl: boolean
  speed: number
}

const props = defineProps<Props>()
const projects = ref<Project[]>([])

const showSwiper = ref(false)

// TODO: Make this dynamic somehow
const jimmyTags = ['comunication', 'brand strategy & development', 'creative direction', 'copywriting', 'cgi', 'campaign', 'art direction', 'tv commercial', 'animation']
const korlatTags = ['creative direction', 'copywriting', 'art direction', 'campaign', 'tv commercial', 'ooh campaign']
const machoTags = ['packaging design', 'creative direction', 'illustration', 'art direction']
const enoughTags = ['creative direction', 'copywriting', 'art direction', 'tv commercial', 'ooh campaign']
const gideonTags = ['brand identity', 'brand strategy & deveiopment', 'art direction', 'graphic design', 'copywriting', 'brand implementation']

const tagsProjects = computed(() => {
    return props.tags.map((tag) => {
        const tagToLowerCased = tag.toLowerCase()
        if (jimmyTags.includes(tagToLowerCased)) {
            return `/work/${projects.value[0].slug}`
        } else if (korlatTags.includes(tagToLowerCased)) {
            return `/work/${projects.value[1].slug}`
        } else if (machoTags.includes(tagToLowerCased)) {
            return `/work/${projects.value[2].slug}`
        } else if (enoughTags.includes(tagToLowerCased)) {
            return `/work/${projects.value[3].slug}`
        } else if (gideonTags.includes(tagToLowerCased)) {
            return `/work/${projects.value[4].slug}`
        } else {
            return '/work'
        }
    })
})

async function fetchProjects () {
    try {
        projects.value = json as Project[] | any
    } catch (e) {
    }
}

await fetchProjects()

onMounted(() => (setTimeout(() => (showSwiper.value = true), 500)))
</script>
