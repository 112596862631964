<template>
    <section class="the-how-section container section">
        <div class="section-number-heading the-how-section__section-number-heading">
            <span>01</span>
            <h2>The how</h2>
        </div>

        <div class="section-head-description">
            Our work does not just get noticed. It gets the conversation going. And it gets the job done.
        </div>

        <ul v-if="projects?.length" class="the-how-section__list">
            <li
                v-for="(item, i) in items"
                :key="item.id"
                class="the-how-section__item"
                :class="[{active: activeItem === i}, `the-how-section__item-${i}`]"
                @mouseenter="activeItem = i"
                @mouseleave="activeItem = -1"
            >
                <nuxt-link v-if="item.slug" :to="`work/${item.slug}`" data-cta="view project" />

                <div v-if="item.picture" class="the-how-section__photo">
                    <AdaptiveImage :image="item.picture" :alt="`${item.title} image`" />
                </div>

                <div class="the-how-section__text-content">
                    <div v-if="item.date" class="the-how-section__timestamp">
                        {{ item.date }}
                    </div>

                    <h3 v-if="item.title" class="the-how-section__title">
                        {{ item.title }}
                    </h3>

                    <nuxt-link v-if="item.slug" :to="item.slug" class="the-how-section__project-link">
                        <span>View project</span>
                        <img :src="'/img/icons/arrow-right.svg'" alt="Link arrow">
                    </nuxt-link>
                </div>

                <div class="the-how-section__count">
                    {{ getPositionCount(i + 1) }}
                </div>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from '#imports'
import { headingStartAnimation, linesStartAnimation } from '~/utils/gsap'
import AdaptiveImage from '~/components/universal/AdaptiveImage.vue'

interface Project {
  id: number | string
  title: string
  slug: string
  lead: string
  picture: {
    default: string
    webp: string
  },
  picture_header: {
    default: string
    webp: string
  },
  client: string
  client_link: string
  info_client: string
  info_about: string
  active?: boolean
  date?: string
  link?: string
}

const props = defineProps<{ projects: Project[] }>()
const activeItem = ref(-1)

const getPositionCount = (i: number): string => `${i}`.length === 1 ? `0${i}` : `${i}`

const items = computed(() => {
    if (props.projects) {
        return props.projects.map(item => ({ ...item, active: false }))
    }
    return []
})

onMounted(async () => {
    await nextTick(() => setTimeout(() => {
        headingStartAnimation('.the-how-section__section-number-heading')
        linesStartAnimation('.the-how-section__item', items.value.length, true)
    }, 500))
})
</script>
